// import { createStore,combineReducers } from 'redux'
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
  robotdatareducer,
  robotlistReducer,
  fleetlatitudeReducer,
  fleetlongitudeReducer,
  fleetIdreducer,
  robotMessageReducer,
  robotidReducer,
  sshendpointReducer,
  robotDetailsReducer,
  orderStatusReducer,
  robotDirectionsReducer,
  userAuthReducer,
} from "./reducers";

const initialState = {};

const reducers = combineReducers({
  fleetIdreducer,
  robotdatareducer,
  robotMessageReducer,
  robotidReducer,
  robotlistReducer,
  sshendpointReducer,
  fleetlatitudeReducer,
  fleetlongitudeReducer,
  robotDetailsReducer,
  orderStatusReducer,
  robotDirectionsReducer,
  userAuthReducer,
});

const store = configureStore({
  reducer: reducers,
  preloadedState: initialState,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
