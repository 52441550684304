import viewer from "./viewer";

export const MainStream = async (
  // whepurl,
  streamLive,
  setStreamLive,
  setViewCount,
  setStreamStats,
  robotId,
  setStreamView
) => {
  const whepVideo = document.getElementById("stream");
  if (whepVideo) {
    whepVideo.muted = true;
    whepVideo.controls = false;
    await viewer(
      // "https://director.millicast.com/api/whep/rGE9Jy/OB02221020",
      // whepurl,
      whepVideo,
      streamLive,
      setStreamLive,
      setViewCount,
      setStreamStats,
      robotId,
      setStreamView
    );
  }
};
