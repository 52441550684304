import { useEffect, useState } from "react";
import { InputAdornment, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  showErrorToast,
  showSuccessToast,
} from "../components/toast/toastType";
import PersonIcon from "@mui/icons-material/Person";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import LockIcon from "@mui/icons-material/Lock";
import "react-toastify/dist/ReactToastify.css";
import { userConstants } from "../redux/constants";

function Login() {
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [loginPasswordHide, setLoginPasswordHide] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user, isAuthenticated, error } = useSelector(
    (state) => state.userAuthReducer
  );

  const loginSubmitHandler = (event) => {
    event.preventDefault();
    dispatch(loginUser(loginEmail, loginPassword));
  };

  useEffect(() => {
    if (isAuthenticated) {
      showSuccessToast("Logged in successfully!");
      dispatch({
        type: userConstants.LOGIN_SUCCESS_MESSAGE_RESET,
      });
      navigate("/selectfleet");
    }

    if (error) {
      showErrorToast("Please enter correct credentials!");
    }

    if (
      sessionStorage.getItem("token") &&
      sessionStorage.getItem("token") !== "null"
    ) {
      navigate("/selectfleet");
    }
  }, [dispatch, isAuthenticated, navigate, error, user]);

  return (
    <div className="DesktopMain-wrapper">
      <div className="Login_page-wholeMain-wrapper">
        <div className="LoginPage-Main-wrapper">
          <form onSubmit={loginSubmitHandler} className="Login-page-form">
            <img
              src="/assets/images/Ottonomy Logo_White_With TM-02.png"
              className="Login-page-form-logo-image"
              alt="logo"
            />

            <div className="Loginpage=-main-form">
              <div className="Loginpage-main-form-inner-div">
                <TextField
                  id="standard-size-normal"
                  fullWidth
                  variant="standard"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon
                          style={{
                            color: "rgb(189,196,224)",
                            width: "30px",
                            height: "30px",
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  value={loginEmail}
                  name="email"
                  onChange={(event) => setLoginEmail(event.target.value)}
                  placeholder="Email ID"
                />
              </div>

              <div className="Loginpage-main-form-inner-div">
                <TextField
                  sx={{ borderRadius: "0px", width: "100%" }}
                  fullWidth
                  id="password"
                  variant="standard"
                  type={`${loginPasswordHide ? "password" : "text"}`}
                  placeholder="Password"
                  value={loginPassword}
                  onChange={(event) => setLoginPassword(event.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon
                          style={{
                            color: "rgb(189,196,224)",
                            width: "30px",
                            height: "30px",
                          }}
                        />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setLoginPasswordHide(!loginPasswordHide)}
                        edge="end"
                        style={{ color: "rgb(189,196,224)" }}
                      >
                        {loginPasswordHide ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    ),
                  }}
                />
              </div>
            </div>
            <div className="Loginpage-btn-wrapper">
              <button type="submit" className="Loginpage-btn-wrapper-btn">
                {" "}
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
