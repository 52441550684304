import { VscChevronLeft, VscChevronRight } from "react-icons/vsc";
import MapboxMain from "./MapboxMain";

const SidebarMap = ({
  sidebar,
  setSideBar,
  showMinimizeBtn,
  setShowMinimizeButton,
  robotPathCheck,
  robotId,
  geofenceZone,
  nogoZones,
  fleetZones,
  robotLatitude,
  robotLongitude,
  robotDetails,
  streamRefLocal,
  slamPosition,
  setSlamPosition,
}) => {
  const showSidebar = () => {
    minimizeEffect();
    setSideBar(!sidebar);
  };

  const minimizeEffect = () => {
    setShowMinimizeButton(false);

    setTimeout(() => {
      setShowMinimizeButton(true);
    }, 2000);
  };

  return (
    <aside
      className="map"
      style={{
        overflow: sidebar ? "hidden" : "overflow",
      }}
    >
      {nogoZones &&
        geofenceZone &&
        fleetZones &&
        robotId &&
        robotDetails[robotId]?.latitude &&
        robotDetails[robotId]?.longitude && (
          <MapboxMain
            robotPathCheck={robotPathCheck}
            robotId={robotId}
            geofenceZone={geofenceZone}
            nogoZones={nogoZones}
            fleetZones={fleetZones}
            robotLatitude={robotLatitude}
            robotLongitude={robotLongitude}
            width={sidebar ? "100%" : "0%"}
            slamPosition={slamPosition}
            setSlamPosition={setSlamPosition}
          />
        )}
      {JSON.parse(process.env.REACT_APP_STREAM_SELF) && (
        <>
          {showMinimizeBtn && (
            <span
              id="minimize__ref"
              className={`sidebar__minimize__btn`}
              onClick={showSidebar}
              style={{
                right: sidebar && "-23px",
                left: !sidebar && "-20px",
                justifyContent: sidebar ? "start" : "end",
                visibility: sidebar ? "hidden" : "visible",
                zIndex: sidebar ? 2 : 0,
                background: !sidebar && "#735AB6",
                top: sidebar
                  ? "calc((100vh - 25%) / 2)"
                  : "calc((100vh - 25%) / 2)",
                display: streamRefLocal === "block" ? "flex" : "none",
              }}
            >
              {sidebar ? (
                <VscChevronLeft className="sidebar__minimize__btn__icon" />
              ) : (
                <VscChevronRight className="sidebar__minimize__btn__icon" />
              )}
            </span>
          )}
        </>
      )}
    </aside>
  );
};

export default SidebarMap;
