import { Director, View } from "@millicast/sdk";
import { WHEPClient } from "./whep.js";
const tokenGenerator = (robotId) =>
  Director.getSubscriber({
    streamName: robotId,
    // streamName: "testingstream",
    // streamName: "OB02221015",
    streamAccountId: process.env.REACT_APP_STREAM_ACCOUNT_ID,
    // subscriberToken: process.env.REACT_APP_SUBSCRIBER_TOKEN,
  }).catch((error) => {
    console.error(error);
  });
const viewer = async (
  video,
  streamLive,
  setStreamLive,
  setViewCount,
  setStreamStats,
  robotId,
  setStreamView
) => {
  const accountId = process.env.REACT_APP_STREAM_ACCOUNT_ID
  const millicastView = new View(
    robotId,
    // "OB02221015",
    () => tokenGenerator(robotId)
    // video
  );

  const activeSources = new Set();
  setStreamView(millicastView);

  millicastView.on("broadcastEvent", async (event) => {
    const { name, data } = event;
    switch (name) {
      case "active":
        // activeSources.add(data.sourceId);
        setStreamLive(true);

        const pc = new RTCPeerConnection();

        pc.addTransceiver("video", {
          direction: "recvonly",
        });

        pc.addTransceiver("audio", {
          direction: "recvonly",
        });

        //Create whip client
        const whep = new WHEPClient();

        //Start publishing
        await whep.view(
          pc,
          `https://director.millicast.com/api/whep/${accountId}/${robotId}`,
          // `https://director.millicast.com/api/whep/rGE9Jy/OB02221015`,
          // process.env.REACT_APP_SUBSCRIBER_TOKEN
        );

        const stream = new MediaStream();

        pc.getReceivers().forEach((r) => {
          stream.addTrack(r.track);
        });

        // Assign stream to video media element
        video.srcObject = stream;

        // Play the stream
        let playPromise = video.play();
        if (playPromise !== undefined) {
          playPromise
            .then(() => {
              // console.log("Stream Playing");
            })
            .catch((error) => {
              console.log(error);
            });
        }

        break;

      case "inactive":
        // activeSources.delete(data.sourceId);
        if (activeSources.size === 0) {
          setStreamLive(false);
        }
        break;
      case "viewercount":
        setViewCount(data.viewercount);
        break;
      default:
        break;
    }
  });

  try {
    await millicastView.connect({
      events: ["active", "inactive", "layers", "viewercount"],
    });
  } catch (e) {
    await millicastView.reconnect();
  }

  millicastView.webRTCPeer.on("stats", (stats) => {
    setStreamStats(stats);
  });
  millicastView.webRTCPeer.initStats();
};

export default viewer;
