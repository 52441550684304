import { Box, Modal } from "@mui/material";
import { memo, useState } from "react";
import { showErrorToast, showSuccessToast } from "../toast/toastType";
import { RobotModeById } from "../../apis";

function AutorunMoving(props) {
  const [open, setOpen] = useState(false);

  const token = sessionStorage.getItem("token");

  const handleOpen = () => {
    setOpen(true);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 825,
    bgcolor: "#282F42",
    p: 4,
    borderRadius: "11px",
    boxShadow: "0px 0px 20px rgb(0 0 0 / 40%)",
  };

  const handleClose = (reason) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
  };

  const handleautorunmoving = (robotId, runningState) => {
    RobotModeById(robotId, token, runningState)
      .then((res) => {
        showSuccessToast("Running state success!");
      })
      .catch((err) => {
        showErrorToast("Running state failed!");
      });
    handleClose();
  };

  const getRobotRunningState = (robotRunningState) => {
    switch (robotRunningState) {
      case "ERROR":
        return (
          <button
            className="robot__teleop robotRunningState_autoSystemFault"
            onClick={handleOpen}
          >
            {" "}
            Error{" "}
          </button>
        );
      case "AUTO_RUN_OBSTACLE":
        return (
          <button
            className="robot__teleop robotRunningState_teleop_Autorun"
            onClick={handleOpen}
          >
            {" "}
            AUTO OBSTACLE{" "}
          </button>
        );
      case "AUTO_RUN":
        return (
          <button
            className="robot__teleop robotRunningState_teleop_Autorun"
            onClick={handleOpen}
          >
            {" "}
            AUTO RUN{" "}
          </button>
        );
      case "TELE_OP_OBSTACLE":
        return (
          <button
            className="robot__teleop robotRunningState_state_Auto_Obstacle-Teleop_Obstacle"
            onClick={handleOpen}
          >
            {" "}
            TELEOP OBSTACLE{" "}
          </button>
        );
      case "TELE_OP":
        return (
          <button
            className="robot__teleop robotRunningState_state_Teleop_Autorun"
            onClick={handleOpen}
          >
            {" "}
            TELEOP{" "}
          </button>
        );
      case "MANUAL":
        return (
          <button
            className="robot__teleop robotRunningState_state_Teleop_Autorun"
            onClick={handleOpen}
          >
            {" "}
            MANUAL{" "}
          </button>
        );
      case "AUTO_SYSTEM_FAULT":
        return (
          <button
            className="robot__teleop robotRunningState_autoSystemFault"
            onClick={handleOpen}
          >
            {" "}
            AUTO SYSTEM FAULT{" "}
          </button>
        );
      case "AUTO_OBSTACLE":
        return (
          <button
            className="robot__teleop robotRunningState_state_Auto_Obstacle-Teleop_Obstacle"
            onClick={handleOpen}
          >
            {" "}
            AUTO OBSTACLE{" "}
          </button>
        );
      case "AUTO_RUN_MOVING":
        return (
          <button
            className="robot__teleop robotRunningState_autorunmoving"
            onClick={handleOpen}
          >
            {" "}
            AUTO RUN MOVING{" "}
          </button>
        );
      case "TELE_OP_MOVING":
        return (
          <button
            className="robot__teleop robotRunningState_teleopmoving"
            onClick={handleOpen}
          >
            {" "}
            TELEOP MOVING
          </button>
        );
      case "PAYLOAD_STATE":
        return (
          <button
            className="robot__teleop robotRunningState_payloadState"
            onClick={handleOpen}
          >
            {" "}
            PAYLOAD STATE{" "}
          </button>
        );
      default:
        return (
          <button
            className="robot__teleop robotRunningState_default"
            onClick={handleOpen}
          >
            {robotRunningState}{" "}
          </button>
        );
    }
  };

  const getrobotmodalbuttons = (robotRunningState) => {
    switch (robotRunningState) {
      case "AUTO_SYSTEM_FAULT":
        return (
          <>
            <button
              className="Modal-btn Modal-btn-red"
              onClick={() =>
                handleautorunmoving(props.robotId, "AUTO_RUN_MOVING")
              }
            >
              {" "}
              AUTO RUN MOVING
            </button>
            <button
              className="Modal-btn Modal-btn-red"
              onClick={() =>
                handleautorunmoving(props.robotId, "TELE_OP_MOVING")
              }
            >
              {" "}
              TELEOP MOVING
            </button>
            <button
              className="Modal-btn Modal-btn-red"
              onClick={() =>
                handleautorunmoving(props.robotId, "PAYLOAD_STATE")
              }
            >
              {" "}
              PAYLOAD STATE
            </button>
          </>
        );
      case "AUTO_OBSTACLE":
        return (
          <>
            <button
              className="Modal-btn Modal-btn-red"
              onClick={() =>
                handleautorunmoving(props.robotId, "AUTO_RUN_MOVING")
              }
            >
              {" "}
              AUTO RUN MOVING
            </button>
            <button
              className="Modal-btn Modal-btn-red"
              onClick={() =>
                handleautorunmoving(props.robotId, "PAYLOAD_STATE")
              }
            >
              {" "}
              PAYLOAD STATE
            </button>
            <button
              className="Modal-btn Modal-btn-red"
              onClick={() =>
                handleautorunmoving(props.robotId, "TELE_OP_MOVING")
              }
            >
              {" "}
              TELEOP MOVING
            </button>
          </>
        );
      case "AUTO_RUN_MOVING":
        return (
          <>
            <button
              className="Modal-btn Modal-btn-red"
              onClick={() =>
                handleautorunmoving(props.robotId, "PAYLOAD_STATE")
              }
            >
              {" "}
              PAYLOAD STATE
            </button>
            <button
              className="Modal-btn Modal-btn-red"
              onClick={() => handleautorunmoving(props.robotId, "AUTO_RUN")}
            >
              {" "}
              AUTO RUN
            </button>
            <button
              className="Modal-btn Modal-btn-red"
              onClick={() =>
                handleautorunmoving(props.robotId, "TELE_OP_MOVING")
              }
            >
              {" "}
              TELEOP MOVING
            </button>
          </>
        );
      case "TELE_OP_MOVING":
        return (
          <>
            <button
              className="Modal-btn Modal-btn-red"
              onClick={() =>
                handleautorunmoving(props.robotId, "AUTO_RUN_MOVING")
              }
            >
              {" "}
              AUTO RUN MOVING
            </button>
            <button
              className="Modal-btn Modal-btn-red"
              onClick={() =>
                handleautorunmoving(props.robotId, "PAYLOAD_STATE")
              }
            >
              {" "}
              PAYLOAD STATE
            </button>
          </>
        );
      case "PAYLOAD_STATE":
        return (
          <>
            <button
              className="Modal-btn Modal-btn-red"
              onClick={() =>
                handleautorunmoving(props.robotId, "AUTO_RUN_MOVING")
              }
            >
              {" "}
              AUTO RUN MOVING
            </button>
            <button
              className="Modal-btn Modal-btn-red"
              onClick={() =>
                handleautorunmoving(props.robotId, "TELE_OP_MOVING")
              }
            >
              {" "}
              TELEOP MOVING
            </button>
          </>
        );
      default:
        return (
          <>
            <button
              className="Modal-btn Modal-btn-red"
              onClick={() =>
                handleautorunmoving(props.robotId, "AUTO_RUN_MOVING")
              }
            >
              {" "}
              AUTO RUN MOVING
            </button>
            <button
              className="Modal-btn Modal-btn-red"
              onClick={() =>
                handleautorunmoving(props.robotId, "TELE_OP_MOVING")
              }
            >
              {" "}
              TELEOP MOVING
            </button>
            <button
              className="Modal-btn Modal-btn-red"
              onClick={() =>
                handleautorunmoving(props.robotId, "PAYLOAD_STATE")
              }
            >
              {" "}
              PAYLOAD STATE
            </button>
          </>
        );
    }
  };
  return (
    <>
      {getRobotRunningState(props.robotData?.robotRunningState)}
      <Modal
        backdrop="static"
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <p className="Modal-wrapper-p">SWITCH TO</p>
          <div className="Modal-buttons-wrapper d-flex-center-center">
            {getrobotmodalbuttons(props.robotData?.robotRunningState)}
          </div>
          <div className="d-flex-center-center" style={{ marginTop: "20px" }}>
            <button className="Modal-btn" onClick={handleClose}>
              Cancel
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default memo(AutorunMoving);
