import { useState, useEffect, useRef } from "react";
import { BsChevronLeft, BsThreeDotsVertical } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { Switch } from "antd";
import {
  showErrorToast,
  showSuccessToast,
  showSSHEndPoint,
} from "../../toast/toastType";
import {
  Stack,
  Button,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Modal,
  Box,
} from "@mui/material";
import {
  DisengageMotors,
  EmergencyStop,
  openSSH,
  ReleaseEmergencyStop,
  changeHeadLightStatus,
  changeRingLightStatus,
  closeDoorOne,
  closeDoorTwo,
  openDoorOne,
  openDoorTwo,
  resetDiagnostics,
  robotListByFleetId,
  sendHome,
  restartvideo,
} from "../../../apis";
import LogoutModal from "../modals/LogoutModal";
import { useDispatch } from "react-redux";
import { sshEndPointAction } from "../../../redux/actions";

const RobotStatusBar = ({
  robotId,
  robotDetails,
  state,
  setState,
  slamPosition,
  setSlamPosition,
}) => {
  const [open, setOpen] = useState(false);
  const [isStop, setisStop] = useState();
  const [isMotorsEngage, setIsMotorsEngage] = useState(false);
  const [isSSHOpen, setIsSSHOpen] = useState(true);
  const [openSSHModal, setOpenSShModal] = useState(false);
  const [closeSSHModal, setCloseSSHModal] = useState(false);
  const [openEngageMotorsModal, setOpenEngageMotorsModal] = useState(false);
  const [openDisengageMotorsModal, setOpenDisengageMotorsModal] =
    useState(false);

  const anchorRef = useRef(null);
  const prevOpen = useRef(open);

  const token = sessionStorage.getItem("token");
  let fleetId = sessionStorage.getItem("fleetId");
  let sshEndPoint = "";

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const motorsStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 825,
    bgcolor: "#282F42",
    p: 4,
    borderRadius: "11px",
    boxShadow: "0px 0px 20px rgb(0 0 0 / 40%)",
  };

  const sshStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 825,
    bgcolor: "#282F42",
    p: 4,
    borderRadius: "11px",
    boxShadow: "0px 0px 20px rgb(0 0 0 / 40%)",
  };

  const controlPageStylesBattery = {
    display: "flex",
    border: "1.5px solid white",
    width: "33px",
    height: "18px",
    borderRadius: "4px",

    paddingBlock: "2px",
    position: "relative",
    alignItems: "center",
  };

  const controlPageStylesOuterPart = {
    height: "11px",
    width: "3.5px",
    background: "white",
    borderTopRightRadius: "100rem",
    borderBottomRightRadius: "100rem",
    position: "absolute",
    left: "31.5px",
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleSendHome = (robotId) => {
    sendHome(robotId, token)
      .then((res) => {
        showSuccessToast("Send Home successful!");
      })
      .catch((err) => {
        showErrorToast("Send Home failed");
      });
  };

  const handleClose = (event) => {
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  const handleStop = (robotId) => {
    EmergencyStop(robotId, token)
      .then((res) => {
        // console.log('Emergency Stop Response',res)
        showSuccessToast("Emergency Stop successful!");

        setisStop(true);
      })
      .catch((err) => {
        // console.log('Emergency Stop Error',err)

        showErrorToast("Emergency Stop failed!");
        setisStop(false);
      });
    handleClose();
  };

  const handleResetDiagnostics = (robotId) => {
    resetDiagnostics(robotId, token)
      .then((res) => {
        // console.log('Reset Diagnotics Response', res)
        showSuccessToast("Diagnostics Reset Successful!");
      })
      .catch((err) => {
        // console.log('Reset Diagnotics Error', err)
        showErrorToast("Diagnostics Reset Failed!");
      });
    handleClose();
  };

  const handleOpenDoorOne = (robotId) => {
    openDoorOne(robotId, token)
      .then((res) => {
        //  console.log("Open Door 1 Response", res)
        showSuccessToast("Door 1 opened successfully!");
      })
      .catch((err) => {
        //  console.log('Open Door 1 Error',err)
        showErrorToast("Door 1 failed to open!");
      });
  };

  const handleOpenDoorTwo = (robotId) => {
    openDoorTwo(robotId, token)
      .then((res) => {
        // console.log("Open Door 2 Response", res)
        showSuccessToast("Door 2 opened successfully!");
      })
      .catch((err) => {
        //  console.log('Open Door 2 Error',err)
        showErrorToast("Door 2 failed to open!");
      });
  };

  const handleClosedoorone = (robotId) => {
    closeDoorOne(robotId, token)
      .then((res) => {
        //  console.log("Close Door 1 Response", res)
        showSuccessToast("Door 1 closed successfully!");
      })
      .catch((err) => {
        //  console.log('Close Door 1 Error',err)
        showErrorToast("Door 1 failed to close!");
      });
  };

  const handleCloseDoorTwo = (robotId) => {
    closeDoorTwo(robotId, token)
      .then((res) => {
        // console.log("Close Door 2 Response", res)
        showSuccessToast("Door 2 closed successfully!");
      })
      .catch((err) => {
        //  console.log('Close Door 2 Error',err)
        showErrorToast("Door 2 failed to close!");
      });
  };

  const handleRelease = (robotId) => {
    ReleaseEmergencyStop(robotId, token)
      .then((res) => {
        // console.log("Release Emergency Stop Response", res);
        showSuccessToast("Release stopped successfully!");
        setisStop(false);
      })
      .catch((err) => {
        // console.log('Release Emergency Stop Response', err)
        showErrorToast("Release failed to stop!");
        setisStop(true);
      });
  };

  const handleringlightChange = (toggleButtonName) => {
    setState({
      ...state,
      [toggleButtonName]: !state[toggleButtonName],
    });

    changeRingLightStatus(robotId, token, state.ringlight)
      .then((res) => {
        // console.log('Change Robot Wander Status Response', res)
        showSuccessToast("Ring Light changed successfully!");
      })
      .catch((error) => {
        // console.log('Change Robot Wander Status Error', error)
        showErrorToast("Ring Light failed to change!");
      });
  };

  const handleHeadlightchange = (toggleButtonName) => {
    setState({
      ...state,
      [toggleButtonName]: !state[toggleButtonName],
    });

    changeHeadLightStatus(robotId, token, state.headlight)
      .then((res) => {
        // console.log('Change Robot Wander Status Response', res)

        showSuccessToast("Head Light changed successfully!");
      })
      .catch((error) => {
        // console.log('Change Robot Wander Status Error', error)
        showErrorToast("Head Light failed to change!");
      });
  };

  const handleStreamClientChange = (toggleButtonName) => {
    setState({
      ...state,
      [toggleButtonName]: !state[toggleButtonName],
    });

    // showErrorToast("Stream Client is not currently integrated!");
  };

  const handleDisengageMotors = (robotId, state) => {
    DisengageMotors(robotId, token, state)
      .then((res) => {
        setIsMotorsEngage(!isMotorsEngage);
        if (state === "ENGAGE_MOTORS") {
          showSuccessToast("Motor Engaged successfully!");
        } else {
          showSuccessToast("Motor Disengaged successfully!");
        }
      })
      .catch((err) => {
        if (state === "ENGAGE_MOTORS") {
          showErrorToast("Motor Engage failed!");
        } else {
          showErrorToast("Motor Disengage failed!");
        }
      });
    if (openDisengageMotorsModal) setOpenDisengageMotorsModal(false);
    if (openEngageMotorsModal) setOpenEngageMotorsModal(false);
    handleClose();
  };

  const handleSSH = (robotId, sessionState) => {
    openSSH(robotId, token, sessionState)
      .then((res) => {
        showSuccessToast("SSH session command sent successfully!");
        setIsSSHOpen(!isSSHOpen);
      })
      .catch((err) => {
        showErrorToast("SSH session command failed!");
      });

    robotListByFleetId(fleetId, token)
      .then((res) => {
        res.data.data.map((item) => {
          if (item.robotId === robotId) {
            sshEndPoint = item.sshEndpoint;
          }
          return null;
        });
        showSSHEndPoint(sshEndPoint);
        dispatch(sshEndPointAction(sshEndPoint));
      })
      .catch((err) => {
        console.error(err);
      });

    if (setOpenSShModal) setOpenSShModal(false);
    if (setCloseSSHModal) setCloseSSHModal(false);
  };

  const handletrobotpath = (toggleButtonName) => {
    setState({
      ...state,
      [toggleButtonName]: !state[toggleButtonName],
    });
    // setToggleDirection(true);

    if (state.robotpathcheck) {
      sessionStorage.removeItem("actionId");
      sessionStorage.removeItem("directionRoutes");
    }

    showSuccessToast("Directions changed successfully!");
  };

  const handleMotors = (motorsChangedStatus) => {
    if (motorsChangedStatus === "engage") {
      setOpenEngageMotorsModal(true);
    } else if (motorsChangedStatus === "disengage") {
      setOpenDisengageMotorsModal(true);
    }
  };

  const handleSSHModal = (sshChangedStatus) => {
    if (sshChangedStatus === "open") {
      setOpenSShModal(true);
    } else if (sshChangedStatus === "close") {
      setCloseSSHModal(true);
    }
  };

  const changeTimeZone = (date, timeZone) => {
    if (typeof date === "string") {
      return new Date(
        new Date(date).toLocaleString("en-US", {
          timeZone,
        })
      );
    }
    return new Date(
      date.toLocaleString("en-US", {
        timeZone,
      })
    );
  };

  const handleRestartStream = (robotId) => {
    restartvideo(robotId, token)
      .then((res) => {
        showSuccessToast(res.data.message);
      })
      .catch((err) => {
        showErrorToast(err.data.message);
      });
  };

  useEffect(() => {
    if (
      robotDetails[robotId] &&
      robotDetails[robotId]?.robotRunningState === "MANUAL"
    ) {
      setIsMotorsEngage(true);
    } else {
      setIsMotorsEngage(false);
    }
  }, [robotDetails, robotId]);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <header>
      <nav>
        <div className="nav__left">
          <div onClick={() => navigate(-1)} className="back__btn">
            <BsChevronLeft fontSize={"28px"} className="back__btn__icon" />
          </div>
          <div className="robot__detail">
            <div className="robot__id__container">
              <span className="robot__id"> {robotId} </span>
              <span className="robot__date">
                {" "}
                {robotDetails[robotId] &&
                  changeTimeZone(
                    new Date(),
                    robotDetails[robotId].timezone
                  ).toUTCString()}{" "}
              </span>
            </div>
            <div className="robot__battery">
              <div
                className="robot__status"
                style={{
                  color:
                    robotDetails[robotId] &&
                    robotDetails[robotId].robotStatus === "AVAILABLE"
                      ? "rgb(113, 207, 98)"
                      : "#e96c67",
                }}
              >
                {robotDetails[robotId] && robotDetails[robotId].robotStatus}
              </div>
              <div className="robot__battery__details">
                <div className="robot__battery__percentage inner-stuff">
                  {robotDetails[robotId] &&
                    Math.floor(
                      parseFloat(robotDetails[robotId].robotBattery, 10)
                    )}
                  %
                </div>
                <div className="robot__battery__logo">
                  <div
                    className="cus-battery-icon"
                    style={controlPageStylesBattery}
                  >
                    <div
                      className="cus-inner-battery"
                      style={{
                        width: `${
                          robotDetails[robotId] &&
                          Math.floor(
                            parseFloat(robotDetails[robotId].robotBattery, 10)
                          )
                        }%`,
                        backgroundColor:
                          robotDetails[robotId] &&
                          Math.floor(
                            parseFloat(robotDetails[robotId].robotBattery, 10)
                          ) < 20
                            ? "red"
                            : "#88cd6f",
                      }}
                    ></div>
                    <div
                      className="cus-battery-part"
                      style={controlPageStylesOuterPart}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="nav__center">
          <div className="robot__speed__details">
            <div className="robot__speed__text">SPEED</div>
            <div className="robot__speed">
              {robotDetails[robotId] &&
                parseFloat(robotDetails[robotId]?.currentSpeed).toFixed(2)}
              m/sec
            </div>
          </div>
          <div className="robot__ring__light__details">
            <div className="robot__ring__light__text">Ring Light</div>
            <div className="robot__ring__light__switch">
              <Switch
                className="robot__ring__light__switch__main"
                checked={state.ringlight}
                onChange={() => handleringlightChange("ringlight")}
              />
            </div>
          </div>
          <div className="robot__head__light__details">
            <div className="robot__head__light__text">Head Light</div>
            <div className="robot__head__light__switch">
              <Switch
                className="robot__head__light__switch__main"
                checked={state.headlight}
                onChange={() => handleHeadlightchange("headlight")}
              />
            </div>
          </div>
          {JSON.parse(process.env.REACT_APP_STREAM_SELF) && (
            <div className="robot__stream__client__details">
              <div className="robot__stream__client__text">Stream Client</div>
              <div className="robot__stream__client__switch">
                <Switch
                  className="robot__stream__client__switch__main"
                  onChange={() => handleStreamClientChange("streamclient")}
                  checked={state.streamclient}
                />
              </div>
            </div>
          )}
          <div className="robot__slam__details">
            {robotDetails[robotId]?.latitudeSlam &&
            robotDetails[robotId]?.longitudeSlam &&
            robotDetails[robotId]?.longitude &&
            robotDetails[robotId]?.latitude ? (
              <>
                <div className="robot__slam__text">Slam</div>
                <div className="robot__slam__switch">
                  <Switch
                    className="robot__slam__switch__main"
                    onChange={() => setSlamPosition(!slamPosition)}
                    checked={slamPosition}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="robot__total__distance__details">
                  <div className="robot__total__distance__text">
                    Total Distance Covered
                  </div>
                  <div className="robot__total__distance">
                    {" "}
                    {robotDetails[robotId] &&
                      Math.floor(
                        parseFloat(
                          robotDetails[robotId].totalDistanceTravelled / 1000
                        ),
                        10
                      )}{" "}
                    km
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="nav__right">
          <span
            className="restart__stream__btn"
            onClick={() => handleRestartStream(robotDetails[robotId]?.robotId)}
          >
            Restart Stream
          </span>
          <span
            className="reset__diagnostics__btn"
            onClick={() =>
              handleResetDiagnostics(robotDetails[robotId]?.robotId)
            }
          >
            Reset Diagnostics
          </span>

          {isStop ? (
            <span
              className="robot__release"
              onClick={() => handleRelease(robotDetails[robotId]?.robotId)}
            >
              Release
            </span>
          ) : (
            <span
              className="robot__e__stop"
              onClick={() => handleStop(robotDetails[robotId]?.robotId)}
            >
              E-STOP
            </span>
          )}
          <Button
            ref={anchorRef}
            id="composition-button"
            aria-controls={open ? "composition-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            style={{
              minWidth: "10px",
            }}
          >
            <span className="robot__expandable__options">
              <BsThreeDotsVertical />
            </span>
          </Button>
        </div>
      </nav>
      <Stack direction="row" spacing={2}>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
          style={{
            zIndex: "99",
          }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <Paper
                style={{
                  background: "#1C1E2B",
                  //   background: "black",
                  borderRadius: "7px",
                  color: "white",
                  padding: "0",
                  marginTop: "15px",
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                    style={{
                      padding: "0",
                    }}
                  >
                    <MenuItem
                      onClick={handleClose}
                      className="menu__extra__options"
                    >
                      {isMotorsEngage ? (
                        <button
                          className="Dashboard_page_sidebar_button"
                          onClick={() => handleMotors("engage")}
                        >
                          Engage Motors{" "}
                        </button>
                      ) : (
                        <button
                          className="Dashboard_page_sidebar_button"
                          onClick={() => handleMotors("disengage")}
                        >
                          Disengage Motors{" "}
                        </button>
                      )}
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleSendHome(robotDetails[robotId]?.robotId)
                      }
                      className="menu__extra__options"
                    >
                      Send Home
                    </MenuItem>
                    <MenuItem
                      onClick={handleClose}
                      className="menu__extra__options"
                    >
                      {isSSHOpen ? (
                        <button
                          className="Dashboard_page_sidebar_button"
                          onClick={() => handleSSHModal("open")}
                        >
                          Open SSH{" "}
                        </button>
                      ) : (
                        <button
                          className="Dashboard_page_sidebar_button"
                          onClick={() => handleSSHModal("close")}
                        >
                          Close SSH{" "}
                        </button>
                      )}
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleResetDiagnostics(robotDetails[robotId]?.robotId)
                      }
                      className="menu__extra__options nav__right__extra__options"
                    >
                      Reset Diagnostics
                    </MenuItem>
                    <MenuItem
                      onClick={() => handletrobotpath("robotpathcheck")}
                      className="menu__extra__options"
                    >
                      <span>Directions</span>
                      <Switch
                        className="robot__directions__switch__main"
                        checked={state.robotpathcheck}
                        // onChange={(event) => handletrobotpath("robotpathcheck",event)}
                        name="robotpathcheck"
                      />
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        navigate(
                          `/control/${robotDetails[robotId].robotID}/config`
                        )
                      }
                      className="menu__extra__options"
                    >
                      Configure Controller
                    </MenuItem>
                    <MenuItem
                      onClick={handleringlightChange}
                      className="menu__extra__options menu__extra__responsive"
                    >
                      <span>Ring Light</span>
                      <Switch
                        className="robot__ring__light__switch__main"
                        checked={state.ringlight}
                        // onChange={handleringlightChange}
                      />
                    </MenuItem>
                    <MenuItem
                      onClick={handleHeadlightchange}
                      className="menu__extra__options menu__extra__responsive"
                    >
                      <span>Head Light</span>
                      <Switch
                        className="robot__head__light__switch__main"
                        checked={state.headlight}
                        // onChange={handleHeadlightchange}
                      />
                    </MenuItem>
                    {robotDetails[robotId]?.robotRunningState ===
                    "PAYLOAD_STATE" ? (
                      <div>
                        {" "}
                        <MenuItem className="menu__extra__options">
                          {robotDetails[robotId]?.door1Status === "CLOSE" ? (
                            <button
                              className="Dashboardpage_OpenDoor_button"
                              onClick={() =>
                                handleOpenDoorOne(
                                  robotDetails[robotId]?.robotId
                                )
                              }
                            >
                              {" "}
                              Open Cabin 1{" "}
                            </button>
                          ) : (
                            <button
                              className="Dashboardpage_CloseDoor_button"
                              onClick={() =>
                                handleClosedoorone(
                                  robotDetails[robotId]?.robotId
                                )
                              }
                            >
                              {" "}
                              Close Cabin 1{" "}
                            </button>
                          )}
                        </MenuItem>
                        <MenuItem className="menu__extra__options ">
                          {robotDetails[robotId]?.door2Status === "CLOSE" ? (
                            <button
                              className="Dashboardpage_OpenDoor_button"
                              onClick={() =>
                                handleOpenDoorTwo(
                                  robotDetails[robotId]?.robotId
                                )
                              }
                            >
                              {" "}
                              Open Cabin 2{" "}
                            </button>
                          ) : (
                            <button
                              className="Dashboardpage_CloseDoor_button"
                              onClick={() =>
                                handleCloseDoorTwo(
                                  robotDetails[robotId]?.robotId
                                )
                              }
                            >
                              {" "}
                              Close Cabin 2{" "}
                            </button>
                          )}
                        </MenuItem>
                      </div>
                    ) : (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {" "}
                        <MenuItem
                          //   onClick={handleClose}
                          // onClick={() => handleStop(robotDetails[robotId]?.robotId)}
                          className="menu__extra__options"
                        >
                          <button
                            className="Dashboard_page_OpenDoor_deactivate"
                            type="button"
                            disabled
                          >
                            {" "}
                            Open Cabin 1{" "}
                          </button>
                        </MenuItem>
                        <MenuItem
                          //   onClick={handleClose}
                          // onClick={() => handleStop(robotDetails[robotId]?.robotId)}
                          className="menu__extra__options"
                        >
                          <button
                            className="Dashboard_page_OpenDoor_deactivate"
                            type="button"
                            disabled
                          >
                            {" "}
                            Open Cabin 2{" "}
                          </button>{" "}
                        </MenuItem>
                      </div>
                    )}
                    <MenuItem
                      //   onClick={handleClose}
                      onClick={() => handleStop(robotDetails[robotId]?.robotId)}
                      className="menu__extra__options nav__right__extra__options"
                    >
                      E-STOP
                    </MenuItem>
                    <MenuItem
                      //   onClick={handleClose}
                      //   onClick={handlelogout}
                      className="menu__extra__options"
                    >
                      <LogoutModal dashboard={true} />
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Stack>

      <Modal
        backdrop="static"
        open={openEngageMotorsModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={motorsStyle}>
          <p className="Modal-wrapper-p">Do you want to Engage the Motors?</p>
          <div className="Modal-buttons-wrapper d-flex-center-center">
            <button
              className="Modal-btn"
              onClick={() => setOpenEngageMotorsModal(false)}
            >
              {" "}
              No
            </button>
            <button
              className="Modal-btn Modal-btn-red"
              onClick={() => handleDisengageMotors(robotId, "ENGAGE_MOTORS")}
            >
              {" "}
              Yes
            </button>
          </div>
        </Box>
      </Modal>
      <Modal
        backdrop="static"
        open={openDisengageMotorsModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={motorsStyle}>
          <p className="Modal-wrapper-p">
            Do you want to Disengage the Motors ?
          </p>
          <div className="Modal-buttons-wrapper d-flex-center-center">
            <button
              className="Modal-btn"
              onClick={() => setOpenDisengageMotorsModal(false)}
            >
              {" "}
              No
            </button>
            <button
              className="Modal-btn Modal-btn-red"
              onClick={() => handleDisengageMotors(robotId, "DISENGAGE_MOTORS")}
            >
              {" "}
              Yes
            </button>
          </div>
        </Box>
      </Modal>

      <Modal
        backdrop="static"
        open={openSSHModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={sshStyle}>
          <p className="Modal-wrapper-p">Do you want to Open SSH?</p>
          <div className="Modal-buttons-wrapper d-flex-center-center">
            <button
              className="Modal-btn"
              onClick={() => setOpenSShModal(false)}
            >
              {" "}
              No
            </button>
            <button
              className="Modal-btn Modal-btn-red"
              onClick={() => handleSSH(robotId, "OPEN")}
            >
              {" "}
              Yes
            </button>
          </div>
        </Box>
      </Modal>
      <Modal
        backdrop="static"
        open={closeSSHModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={sshStyle}>
          <p className="Modal-wrapper-p">Do you want to Close SSH?</p>
          <div className="Modal-buttons-wrapper d-flex-center-center">
            <button
              className="Modal-btn"
              onClick={() => setCloseSSHModal(false)}
            >
              {" "}
              No
            </button>
            <button
              className="Modal-btn Modal-btn-red"
              onClick={() => handleSSH(robotId, "CLOSE")}
            >
              {" "}
              Yes
            </button>
          </div>
        </Box>
      </Modal>
    </header>
  );
};

export default RobotStatusBar;
