import { memo, useCallback, useEffect, useRef, useState } from "react";
import { Box, Modal, Typography } from "@mui/material";
import { getfleetzones, getgeofenceZone, getnogozones } from "../apis";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getRobotDetail, storeRobotDetails } from "../redux/actions";
import { useController } from "../custom-hooks/useController";
import { MainStream } from "../components/stream/main";
import { createJoymap, createQueryModule } from "joymap";
import RobotStatusBar from "../components/layout/navbar/RobotStatusBar";
import SidebarMap from "../components/layout/sidebar/SidebarMap";
import SidebarTeleop from "../components/layout/sidebar/SidebarTeleop";
// import Draggable from "react-draggable";
import ControlBtn from "../components/joystick/ControlBtn";
import Stream from "../components/stream/Stream";
import "../App.css";

// let joymap;
// let module;
// if (JSON.parse(process.env.REACT_APP_STREAM_SELF)) {
const joymap = createJoymap();
const module = createQueryModule();
joymap.addModule(module);
// }

function Dashboard() {
  const [geofencezone, setgeofencezone] = useState();
  const [fleetzones, setfleetzones] = useState();
  const [nogozones, setnogozones] = useState();
  const [streamLive, setStreamLive] = useState(false);
  const [viewCount, setViewCount] = useState(0);
  const [streamView, setStreamView] = useState();
  const [streamStats, setStreamStats] = useState(0);
  const [showStreamStats, setShowStreamStats] = useState(false);
  const [, setIsPictureInPicture] = useState(false);
  const [isMetadataLoaded, setIsMetadataLoaded] = useState(false);
  const [, updatedState] = useState({});
  const [conlock, setConLock] = useState(false);
  const [state, setState] = useState({
    testingmode: false,
    wandermode: false,
    ringlight: false,
    headlight: false,
    robotpathcheck: false,
    googlemap: false,
    streamclient: false,
  });

  const streamRef = useRef(null);

  const robotID = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const robotDetails = useSelector((state) => state.robotDetailsReducer);

  let fleetId = sessionStorage.getItem("fleetId");
  let robotId = robotID?.state?.name?.robotId;

  const [slamPosition, setSlamPosition] = useState(
    robotDetails[robotId]?.latitudeSlam && robotDetails[robotId]?.longitudeSlam
      ? true
      : false
  );

  const isLogged = sessionStorage.getItem("isLoggedin");
  const token = sessionStorage.getItem("token");
  const streamRefLocal = localStorage.getItem("streamRef");
  const lock = (conlock) => {
    setConLock(conlock);
  };
  const forceUpdate = useCallback(() => updatedState({}), []);

  const getbordertop = (robotRunningState) => {
    switch (robotRunningState) {
      case "AUTO_SYSTEM_FAULT":
        return "Dashboard-Page-bottom-div-Main-wrapper d-flex-center AutoSystem_fault_error_bordertop";
      case "ERROR":
        return "Dashboard-Page-bottom-div-Main-wrapper d-flex-center AutoSystem_fault_error_bordertop";
      case "AUTO_OBSTACLE":
        return "Dashboard-Page-bottom-div-Main-wrapper d-flex-center AutoObstacle_teleop_obstacle_bordertop";
      case "AUTO_RUN_OBSTACLE":
        return "Dashboard-Page-bottom-div-Main-wrapper d-flex-center Teleop_autoRun_borderTop";
      case "AUTO_RUN_MOVING":
        return "Dashboard-Page-bottom-div-Main-wrapper d-flex-center AutoRun_moving";
      case "AUTO_RUN":
        return "Dashboard-Page-bottom-div-Main-wrapper d-flex-center Teleop_autoRun_borderTop";
      case "TELE_OP_OBSTACLE":
        return "Dashboard-Page-bottom-div-Main-wrapper d-flex-center AutoObstacle_teleop_obstacle_bordertop";
      case "TELE_OP_MOVING":
        return "Dashboard-Page-bottom-div-Main-wrapper d-flex-center teleopMoving_bordertop";
      case "TELE_OP":
        return "Dashboard-Page-bottom-div-Main-wrapper d-flex-center Teleop_autoRun_borderTop";
      case "PAYLOAD_STATE":
        return "Dashboard-Page-bottom-div-Main-wrapper d-flex-center payloadState_bordertop";
      case "MANUAL":
        return "Dashboard-Page-bottom-div-Main-wrapper d-flex-center Teleop_autoRun_borderTop";
      default:
        return "Dashboard-Page-bottom-div-Main-wrapper d-flex-center defaultBorderTop";
    }
  };

  const getTeleopDetailsBorderTop = (robotRunningState) => {
    switch (robotRunningState) {
      case "AUTO_SYSTEM_FAULT":
        return "AutoSystem_fault_error_bordertop";
      case "ERROR":
        return "AutoSystem_fault_error_bordertop";
      case "AUTO_OBSTACLE":
        return "AutoObstacle_teleop_obstacle_bordertop";
      case "AUTO_RUN_OBSTACLE":
        return "Teleop_autoRun_borderTop";
      case "AUTO_RUN_MOVING":
        return "AutoRun_moving";
      case "AUTO_RUN":
        return "Teleop_autoRun_borderTop";
      case "TELE_OP_OBSTACLE":
        return "AutoObstacle_teleop_obstacle_bordertop";
      case "TELE_OP_MOVING":
        return "teleopMoving_bordertop";
      case "TELE_OP":
        return "Teleop_autoRun_borderTop";
      case "PAYLOAD_STATE":
        return "payloadState_bordertop";
      case "MANUAL":
        return "Teleop_autoRun_borderTop";
      default:
        return "defaultBorderTop";
    }
  };

  const openExtendedWindow = async () => {
    const windowDetails = await window.getScreenDetails();

    if (window.screen.isExtended) {
      windowDetails.screens.forEach((screen) => {
        if (!screen.isPrimary) {
          window.open(
            `/stream/${robotId}`,
            "",
            `width=${screen.availWidth},height=${screen.height},top=${screen.top},left=${screen.left},fullscreen=yes`
          );
        } else {
          localStorage.setItem("streamRef", "none");
        }
      });
    }
  };

  const handleExtendScreen = async () => {
    if (showStreamStats) {
      setShowStreamStats(false);
    }
    await openExtendedWindow();
  };

  const handleReduceScreen = () => {
    localStorage.setItem("streamRef", "block");
  };

  const togglePictureInPicture = () => {
    const videoElement = streamRef.current;

    if (!isMetadataLoaded) {
      console.log("Video metadata is not loaded yet.");
      return;
    }

    if (document.pictureInPictureElement === videoElement) {
      document
        .exitPictureInPicture()
        .then(() => {
          setIsPictureInPicture(false);
          streamRef.current.style.display = "block";
        })
        .catch((error) => {
          console.error("Failed to exit Picture-in-Picture mode:", error);
        });
    } else {
      videoElement
        .requestPictureInPicture()
        .then(() => {
          setIsPictureInPicture(true);
          // streamRef.current.style.display = "none";
        })
        .catch((error) => {
          console.error("Failed to enter Picture-in-Picture mode:", error);
        });
    }
  };

  const handleMetadataLoaded = () => {
    setIsMetadataLoaded(true);
  };

  const updateState = useCallback(async () => {
    let robotIdfromURL = window.location.pathname.substring(
      window.location.pathname.lastIndexOf("/") + 1
    );
    if (robotId === null || robotId === "" || robotId === undefined) {
      dispatch(storeRobotDetails(fleetId));
      let singlerobotdata = Object.keys(robotDetails).filter(
        (item) => item === robotIdfromURL
      );

      robotId = singlerobotdata.robotId;
    }

    if (isLogged === "LoginFalse") {
      window.location.replace("/");
    } else {
      dispatch(getRobotDetail(robotId));

      sessionStorage.setItem("kmlurl", robotDetails[robotId]?.kmlURL);
    }
  }, [dispatch, fleetId]);

  const useIntervalAsync = (callbackFunction, intervalInMs) => {
    const timeout = useRef();

    const apiCall = useCallback(async () => {
      await callbackFunction();
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
      timeout.current = window.setTimeout(apiCall, intervalInMs);
    }, [callbackFunction, intervalInMs]);

    useEffect(() => {
      apiCall();

      return () => {
        clearTimeout(timeout.current);
      };
    }, []);
  };

  useIntervalAsync(updateState, 2000);

  // useEffect(() => {
  //   if (
  //     token === "" ||
  //     token === "null" ||
  //     token === null ||
  //     token === undefined
  //   ) {
  //     navigate("/");
  //   }

  //   getgeofenceZone(fleetId, token)
  //     .then((res) => {
  //       //  console.log('Geofence Response', res)
  //       if (!res.data.geofencezone) {
  //         setgeofencezone([]);
  //       } else {
  //         setgeofencezone(res.data.geofence);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(
  //         "Oops, something went wrong while fetching geofencezone!",
  //         err
  //       );
  //     });

  //   getfleetzones(fleetId, token)
  //     .then((res) => {
  //       // console.log('FleetZone Response', res)
  //       if (!res.data.teleoperationZones) {
  //         setfleetzones([]);
  //       } else {
  //         setfleetzones(res.data.teleoperationZones);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(
  //         "Oops, something went wrong while fetching fleetzone!",
  //         err
  //       );
  //     });

  //   getnogozones(fleetId, token)
  //     .then((res) => {
  //       // console.log('NogoZone Response', res)
  //       if (!res.data.nogoZones) {
  //         setnogozones([]);
  //       } else {
  //         setnogozones(res.data.nogoZones);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("Oops, something went wrong while fetching nogozone!", err);
  //     });
  // });

  useEffect(() => {
    if (JSON.parse(process.env.REACT_APP_STREAM_SELF)) {
      MainStream(
        // robotDetails[robotId] && robotDetails[robotId].whepurl,
        streamLive,
        setStreamLive,
        setViewCount,
        setStreamStats,
        robotId,
        setStreamView
      );
      joymap.setOnPoll(forceUpdate);
      joymap.start();

      return () => {
        joymap.stop();
      };
    }
  }, [robotId]);

  useEffect(() => {
    if (!streamRefLocal) {
      localStorage.setItem("streamRef", "block");
    }
  });

  const {
    sidebar,
    setSideBar,
    showMinimizeBtn,
    setShowMinimizeButton,
    unlock,
    unlockController,
  } = useController();

  return (
    <>
      <div className="container-fluid DashBoard-page-Main-Whole-wrapper">
        <div
          className={getbordertop(robotDetails[robotId]?.robotRunningState)}
        ></div>
        <>
          <div className="main__controls">
            <RobotStatusBar
              robotId={robotId}
              robotDetails={robotDetails}
              unlockController={unlockController}
              state={state}
              setState={setState}
              slamPosition={slamPosition}
              setSlamPosition={setSlamPosition}
            />
            <main
              className={`relative ${
                JSON.parse(process.env.REACT_APP_STREAM_SELF) && sidebar
                  ? "main__container"
                  : "main__container__toggle"
              } ${
                streamRefLocal === "none" &&
                sidebar &&
                "main__container__without_stream"
              } ${
                !JSON.parse(process.env.REACT_APP_STREAM_SELF) &&
                "main__container__without_stream"
              }`}
            >
              <SidebarMap
                robotDetails={robotDetails}
                sidebar={sidebar}
                setSideBar={setSideBar}
                showMinimizeBtn={showMinimizeBtn}
                setShowMinimizeButton={setShowMinimizeButton}
                robotPathCheck={state.robotpathcheck}
                robotId={robotId}
                geofenceZone={geofencezone}
                nogoZones={nogozones}
                fleetZones={fleetzones}
                robotLatitude={
                  robotDetails[robotId] &&
                  parseFloat(robotDetails[robotId]?.latitude)
                }
                robotLongitude={
                  robotDetails[robotId] &&
                  parseFloat(robotDetails[robotId]?.longitude)
                }
                streamRefLocal={streamRefLocal}
                slamPosition={slamPosition}
                setSlamPosition={setSlamPosition}
              />

              <SidebarTeleop
                robotDetails={robotDetails}
                robotId={robotId}
                unlockController={unlockController}
                sidebar={sidebar}
                showMinimizeBtn={showMinimizeBtn}
                lock={lock}
                teleopBorder={
                  robotDetails[robotId] &&
                  getTeleopDetailsBorderTop(
                    robotDetails[robotId]?.robotRunningState
                  )
                }
                viewCount={viewCount}
                streamLive={streamLive}
              />
              {JSON.parse(process.env.REACT_APP_STREAM_SELF) && (
                <section className="video__stream">
                  <Stream
                    streamRef={streamRef}
                    streamLive={streamLive}
                    handleMetadataLoaded={handleMetadataLoaded}
                    streamStats={streamStats}
                    setStreamStats={setStreamStats}
                    showStreamStats={showStreamStats}
                    streamView={streamView}
                    setShowStreamStats={setShowStreamStats}
                    isMetadataLoaded={isMetadataLoaded}
                    togglePictureInPicture={togglePictureInPicture}
                    handleExtendScreen={handleExtendScreen}
                    handleReduceScreen={handleReduceScreen}
                    streamRefLocal={streamRefLocal}
                  />
                </section>
              )}

              {/* {robotDetails[robotId]?.robotRunningState !== "PAYLOAD_STATE" && (
                <Draggable bounds="body">
                  <div className="border-4 !border-primary cursor-move z-[99] absolute h-52 w-60 bg-[#292F40] bg-opacity-[0.87] inset-y-1/3 rounded-md inset-x-1 p-2">
                    <div className="flex flex-col items-start gap-3">
                      <span className="relative !text-primary text-sm font-bold after:absolute after:-bottom-1 after:left-0 after:h-[3px] after:w-12 after:!bg-primary">
                        Cabin Controls
                      </span>
                      <div className="flex flex-wrap items-center justify-center gap-x-3 gap-y-2.5">
                        <span className="px-2.5 py-1.5 rounded-md !bg-primary text-[11px] w-[45%] font-semibold text-white">
                          Open Cabin 1
                        </span>
                        <span className="px-2.5 py-1.5 rounded-md !bg-primary text-[11px] w-[45%] font-semibold text-white">
                          Open Cabin 2
                        </span>
                        <span className="px-2.5 py-1.5 rounded-md !bg-primary text-[11px] w-[45%] font-semibold text-white">
                          Open Cabin 3
                        </span>
                        <span className="px-2.5 py-1.5 rounded-md !bg-primary text-[11px] w-[45%] font-semibold text-white">
                          Open Cabin 4
                        </span>
                        <span className="px-2.5 py-1.5 rounded-md !bg-primary text-[11px] w-[45%] font-semibold text-white">
                          Open Cabin 5
                        </span>
                        <span className="px-2.5 py-1.5 rounded-md !bg-primary text-[11px] w-[45%] font-semibold text-white">
                          Open Cabin 6
                        </span>
                        <span className="px-2.5 py-1.5 rounded-md !bg-primary text-[11px] w-[45%] font-semibold text-white">
                          Open Cabin 7
                        </span>
                        <span className="px-2.5 py-1.5 rounded-md !bg-primary text-[11px] w-[45%] font-semibold text-white">
                          Open Cabin 8
                        </span>
                      </div>
                    </div>
                  </div>
                </Draggable>
              )} */}
            </main>
            {JSON.parse(process.env.REACT_APP_STREAM_SELF) && (
              <>
                {(streamLive || state.streamclient) && (
                  <div
                    style={{
                      position: "absolute",
                      zIndex: "100",
                      bottom: "0rem",
                      right: "0rem",
                      display: unlock ? "" : "none",
                      pointerEvents: "none",
                    }}
                  >
                    <ControlBtn
                      module={module}
                      pressedColor={`rgb(41 255 2)`}
                      conlock={conlock}
                      robotId={robotId}
                      state={state}
                      setState={setState}
                      latency={
                        streamStats.hasOwnProperty("currentRoundTripTime")
                          ? Math.ceil(streamStats.currentRoundTripTime * 1000)
                          : 1000
                      }
                    ></ControlBtn>
                  </div>
                )}
              </>
            )}
          </div>
          <Modal
            open
            className="landscape__popup__container"
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                p: 4,
              }}
            >
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Change Orientation
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Please rotate your device.
              </Typography>
            </Box>
          </Modal>
        </>
      </div>
    </>
  );
}

export default memo(Dashboard);
