import { useState, useEffect, useRef, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getRobotDirectionbyMapbox,
  robotmsgAction,
} from "../../../redux/actions";
import { getFleetListUser } from "../../../apis";
import { FiNavigation2 } from "react-icons/fi";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

let origin;
let destination;

mapboxgl.accessToken =
  "pk.eyJ1IjoibXJsYWtzaHlhZ3VwdGEiLCJhIjoiY2xpZWZlcTJjMGo4ZDNkczBwc2ZmcW1qMyJ9.Kurk7BS04invJcCwS0m4Pg";

const checkActionId = (commandListData) => {
  if (
    !sessionStorage.getItem("actionId") ||
    sessionStorage.getItem("actionId") === "null"
  ) {
    sessionStorage.setItem(
      "actionId",
      JSON.stringify({
        actionType: commandListData.actionType,
        actionId: commandListData.actionId,
      })
    );
    return true;
  } else {
    if (
      commandListData.actionId !==
      JSON.parse(sessionStorage.getItem("actionId")).actionId
    ) {
      sessionStorage.setItem(
        "actionId",
        JSON.stringify({
          actionType: commandListData.actionType,
          actionId: commandListData.actionId,
        })
      );
      return true;
    } else {
      return false;
    }
  }
};

const isPointNearCircle = (
  pointLat,
  pointLng,
  centerLat,
  centerLng,
  radius
) => {
  var ky = 40000 / 360;
  var kx = Math.cos((Math.PI * centerLat) / 180.0) * ky;
  var dx = Math.abs(centerLng - pointLng) * kx;
  var dy = Math.abs(centerLat - pointLat) * ky;
  return Math.sqrt(dx * dx + dy * dy);
};

const MapboxMain = ({
  robotPathCheck,
  robotId,
  geofenceZone,
  nogoZones,
  fleetZones,
  robotLatitude,
  robotLongitude,
  width,
  slamPosition,
  setSlamPosition,
}) => {
  const robotDetails = useSelector((state) => state.robotDetailsReducer);
  const { robotmsgData } = useSelector((state) => state.robotMessageReducer);
  const { robotDirections } = useSelector(
    (state) => state.robotDirectionsReducer
  );

  const [map, setMap] = useState(null);
  const [zoom, setZoom] = useState(15);
  const [, setPolygonArray] = useState([]);
  const [robotMarker, setRobotMarker] = useState(null);
  const [destinationMarker, setDestinationMarker] = useState(null);
  const [userControllingMap, setUserControllingMap] = useState(false);

  const [nogoZonesCoordinates, setNogoZonesCoordinates] = useState(
    nogoZones.map((data) => [data.lng, data.lat])
  );
  const [geofenceCoordinates, setGeofenceCoordinates] = useState(
    geofenceZone.map((data) => [data.lng, data.lat])
  );

  const mapRef = useRef(null);
  let destinationMarkerForBound = [];

  const dispatch = useDispatch();

  const robotMarkerDiv = document.createElement("div");
  robotMarkerDiv.style.width = "35px";
  robotMarkerDiv.style.height = "35px";
  // robotMarkerDiv.style.backgroundImage =
  //   "url(/assets/images/robot-icon-pickup_1.svg)";
  robotMarkerDiv.style.borderRadius = "100%";
  robotMarkerDiv.style.animation = "example 1s ease-in-out infinite";

  const destinationMarkerDiv = document.createElement("div");
  destinationMarkerDiv.style.width = "35px";
  destinationMarkerDiv.style.height = "35px";
  // destinationMarkerDiv.style.visibility = "hidden";
  destinationMarkerDiv.style.backgroundImage =
    "url(/assets/images/dropoff.svg)";
  destinationMarkerDiv.style.borderRadius = "100%";
  // destinationMarkerDiv.style.animation = "example 1s ease-in-out infinite";

  const updateRobotPath = () => {
    const map = mapRef.current;
    if (!robotPathCheck) {
      if (
        robotDetails[robotId].hasOwnProperty("path") &&
        robotDetails[robotId].path.length > 0 &&
        robotDetails[robotId].hasOwnProperty("robotCommandList") &&
        robotDetails[robotId].robotCommandList.length > 0 &&
        robotDetails[robotId].robotCommandList.find(
          (x) => x.actionPriority === 1
        ) &&
        robotDetails[robotId].robotCommandList
          .find((x) => x.actionPriority === 1)
          .actionType.includes("MOVE") &&
        map.isStyleLoaded()
      ) {
        const newrobotPath = robotDetails[robotId].path.map((pathDirection) => [
          pathDirection.lng,
          pathDirection.lat,
        ]);
        const mySource = map.getSource("robotPath");

        map &&
          mySource &&
          mySource.setData({
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: newrobotPath,
            },
          });

        if (!userControllingMap) {
          // Create a 'LngLatBounds' with both corners at the first coordinate.
          const bounds = new mapboxgl.LngLatBounds(
            newrobotPath[0],
            newrobotPath[0]
          );

          // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
          for (const coord of newrobotPath) {
            bounds.extend(coord);
          }

          bounds.extend([
            slamPosition
              ? parseFloat(robotDetails[robotId].longitudeSlam)
              : parseFloat(robotDetails[robotId].longitude),
            slamPosition
              ? parseFloat(robotDetails[robotId].latitudeSlam)
              : parseFloat(robotDetails[robotId].latitude),
          ]);

          if (destinationMarkerForBound.length !== 0) {
            bounds.extend(destinationMarkerForBound);
          }

          map.fitBounds(bounds, {
            padding: 100,
            bearing: robotDetails[robotId].headingSlam
              ? parseFloat(robotDetails[robotId].headingSlam)
              : 0,
          });
        }

        map.getLayer("path") &&
          map.setPaintProperty("path", "line-color", "#0f0");
      } else {
        const robotPathSource = map.getSource("robotPath");
        map &&
          robotPathSource &&
          robotPathSource.setData({
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: [],
            },
          });
      }
    } else {
      if (
        robotDetails[robotId].hasOwnProperty("robotCommandList") &&
        robotDetails[robotId].robotCommandList.length > 0 &&
        robotDetails[robotId].robotCommandList.find(
          (x) => x.actionPriority === 1
        ) &&
        robotDetails[robotId].robotCommandList
          .find((x) => x.actionPriority === 1)
          .actionType.includes("MOVE") &&
        map.isStyleLoaded() &&
        checkActionId(
          robotDetails[robotId].robotCommandList.find(
            (x) => x.actionPriority === 1
          )
        )
      ) {
        destination = {
          lat: robotDetails[robotId].robotCommandList.find(
            (x) => x.actionPriority === 1
          ).latitude,
          lng: robotDetails[robotId].robotCommandList.find(
            (x) => x.actionPriority === 1
          ).longitude,
        };
        origin = {
          lat: robotLatitude,
          lng: robotLongitude,
        };

        dispatch(getRobotDirectionbyMapbox(origin, destination));
      }

      if (robotDirections.hasOwnProperty("routes")) {
        const mapboxPath = robotDirections.routes[0].geometry.coordinates;
        let tempMapBoxPath = [...mapboxPath];
        let nearest = 0;
        tempMapBoxPath.forEach((path, index) => {
          if (
            isPointNearCircle(
              path[1],
              path[0],
              parseFloat(robotDetails[robotId].latitude),
              parseFloat(robotDetails[robotId].longitude),
              0.01
            ) <
            isPointNearCircle(
              tempMapBoxPath[nearest][1],
              tempMapBoxPath[nearest][0],
              parseFloat(robotDetails[robotId].latitude),
              parseFloat(robotDetails[robotId].longitude),
              0.01
            )
          ) {
            nearest = index;
          }
        });

        tempMapBoxPath.slice(nearest);

        const mySource = map.getSource("robotPath");

        map &&
          mySource &&
          mySource.setData({
            type: "FeatureCollection",
            name: "robotPaths",
            features: [
              {
                type: "Feature",
                properties: {},
                geometry: {
                  type: "LineString",
                  coordinates: tempMapBoxPath,
                },
              },
              {
                type: "Feature",
                geometry: {
                  type: "LineString",
                  coordinates: [
                    [
                      tempMapBoxPath.length && tempMapBoxPath[0][0],
                      tempMapBoxPath.length && tempMapBoxPath[0][1],
                    ],
                    [
                      slamPosition
                        ? robotDetails[robotId]?.longitudeSlam
                        : parseFloat(robotDetails[robotId]?.longitude),
                      slamPosition
                        ? robotDetails[robotId]?.latitudeSlam
                        : parseFloat(robotDetails[robotId]?.latitude),
                    ],
                  ],
                },
              },
            ],
          });
        map.getLayer("path") &&
          map.setPaintProperty("path", "line-color", "#A020F0");

        if (!userControllingMap) {
          // Create a 'LngLatBounds' with both corners at the first coordinate.
          const bounds = new mapboxgl.LngLatBounds(
            tempMapBoxPath[0],
            tempMapBoxPath[0]
          );

          // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
          for (const coord of tempMapBoxPath) {
            bounds.extend(coord);
          }

          bounds.extend([
            slamPosition
              ? parseFloat(robotDetails[robotId].longitudeSlam)
              : parseFloat(robotDetails[robotId].longitude),
            slamPosition
              ? parseFloat(robotDetails[robotId].latitudeSlam)
              : parseFloat(robotDetails[robotId].latitude),
          ]);

          map.fitBounds(bounds, {
            padding: 100,
            bearing: robotDetails[robotId].headingSlam
              ? parseFloat(robotDetails[robotId].headingSlam)
              : 0,
          });
        }
      }
    }
  };

  useEffect(() => {
    const initializeMap = () => {
      const newMap = new mapboxgl.Map({
        container: "map-container-id",
        style: "mapbox://styles/mrlakshyagupta/climr57of00le01pgc0jjgfoh",
        center: [
          slamPosition
            ? robotDetails[robotId].longitudeSlam
            : robotDetails[robotId].longitude,
          slamPosition
            ? robotDetails[robotId].latitudeSlam
            : robotDetails[robotId].latitude,
        ],
        zoom: zoom,
      });

      // Marker type for with/without heading
      robotMarkerDiv.style.backgroundImage = robotDetails[robotId].headingSlam
        ? "url(/assets/images/robot-icon-2.svg)"
        : "url(/assets/images/robot-icon-pickup_1.svg)";

      // Initialize robot marker
      let newRobotMarker;

      if (
        robotDetails[robotId].headingSlam &&
        JSON.parse(process.env.REACT_APP_HEADING)
      ) {
        newRobotMarker = new mapboxgl.Marker(robotMarkerDiv)
          .setLngLat([
            slamPosition
              ? robotDetails[robotId].longitudeSlam
              : robotDetails[robotId].longitude,
            slamPosition
              ? robotDetails[robotId].latitudeSlam
              : robotDetails[robotId].latitude,
          ])
          .setRotation(parseFloat(robotDetails[robotId].headingSlam))
          .setRotationAlignment("map")
          .addTo(newMap);
      } else {
        newRobotMarker = new mapboxgl.Marker(robotMarkerDiv)
          .setLngLat([
            slamPosition
              ? robotDetails[robotId].longitudeSlam
              : robotDetails[robotId].longitude,
            slamPosition
              ? robotDetails[robotId].latitudeSlam
              : robotDetails[robotId].latitude,
          ])
          .addTo(newMap);
      }

      // Initialize destination marker
      const destinationMarker = new mapboxgl.Marker(destinationMarkerDiv)
        .setLngLat([0, 0])
        .addTo(newMap);

      setDestinationMarker(destinationMarker);
      destinationMarkerForBound = [];

      newMap.on("load", () => {
        newMap.addSource("nogozone", {
          type: "geojson",
          data: {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [nogoZonesCoordinates],
            },
          },
        });

        newMap.addLayer({
          id: "nogozoneoutline",
          type: "line",
          source: "nogozone",
          layout: {},
          paint: {
            "line-color": "#f00",
            "line-width": 3,
          },
        });
      });

      newMap.on("load", () => {
        newMap.addSource("geofence", {
          type: "geojson",
          data: {
            type: "Feature",
            geometry: {
              type: "Polygon",

              coordinates: [geofenceCoordinates],
            },
          },
        });

        newMap.addLayer({
          id: "geofenceoutline",
          type: "line",
          source: "geofence",
          layout: {},
          paint: {
            "line-color": "#f00",
            "line-width": 3,
          },
        });
      });

      newMap.on("wheel", () => {
        setUserControllingMap(true);
      });

      newMap.on("drag", () => {
        setUserControllingMap(true);
      });

      let polygonArray = [];
      let offset = 0.00003;

      fleetZones.map((polygon, index) => {
        polygonArray.push(polygon.map((data) => [data.lng, data.lat + offset]));

        newMap.on("load", () => {
          newMap.addSource("fleetzone-" + index, {
            type: "geojson",
            data: {
              type: "Feature",
              geometry: {
                type: "Polygon",

                coordinates: [polygonArray[index]],
              },
            },
          });

          newMap.addLayer({
            id: "fleetzoneoutline-" + index,
            type: "fill",
            source: "fleetzone-" + index,
            layout: {},
            paint: {
              "fill-color": "#7859bc",
              "fill-opacity": 0.75,
            },
          });
        });

        return polygonArray;
      });

      // Add zoom and rotation controls to the map.
      newMap.addControl(new mapboxgl.NavigationControl());

      newMap.on("move", () => {
        setZoom(newMap.getZoom().toFixed(2));
      });

      newMap.on("idle", function () {
        newMap.resize();
      });

      setPolygonArray(polygonArray);
      setRobotMarker(newRobotMarker);
      setMap(newMap);
      mapRef.current = newMap;
    };

    if (!map) {
      initializeMap();
    }
    return () => {};
  }, [map, nogoZones, fleetZones, geofenceZone, setMap]);

  useEffect(() => {
    if (
      (robotmsgData &&
        robotmsgData.length > 0 &&
        !robotmsgData.find((robotData) => robotData.location === "zaragoza")) ||
      !robotmsgData
    ) {
      getFleetListUser(
        sessionStorage.getItem("useremail"),
        sessionStorage.getItem("token")
      ).then((res) => {
        dispatch(robotmsgAction(res.data.fleet));
      });
    }

    if (map && map.isStyleLoaded()) {
      if (
        robotmsgData &&
        robotmsgData.find(
          (robotData) => robotData.fleetName === "Peoria_Riverfront"
        )
      ) {
        !map.hasImage("custom-marker") &&
          map.loadImage(
            "https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png",
            (error, image) => {
              if (error) throw error;
              map.addImage("custom-marker", image);
            }
          );

        // Add a GeoJSON source with 2 points
        !map.getSource("riverFront") &&
          map.addSource("riverFront", {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              name: "riverFronts",
              features: [
                {
                  type: "Feature",
                  properties: { Name: "Point 1" },
                  geometry: {
                    type: "Point",
                    coordinates: [-89.591121, 40.68748],
                  },
                },
                {
                  type: "Feature",
                  properties: { Name: "Point 2" },
                  geometry: {
                    type: "Point",
                    coordinates: [-89.588448, 40.688114],
                  },
                },
                {
                  type: "Feature",
                  properties: { Name: "Point 3" },
                  geometry: {
                    type: "Point",
                    coordinates: [-89.587556, 40.688682],
                  },
                },
                {
                  type: "Feature",
                  properties: { Name: "Point 4" },
                  geometry: {
                    type: "Point",
                    coordinates: [-89.586918, 40.689669],
                  },
                },
                {
                  type: "Feature",
                  properties: { Name: "Point 5" },
                  geometry: {
                    type: "Point",
                    coordinates: [-89.589788, 40.688297],
                  },
                },
                {
                  type: "Feature",
                  properties: { Name: "Polygon 1" },
                  geometry: {
                    type: "Polygon",
                    coordinates: [
                      [
                        [-89.5924303, 40.6866848],
                        [-89.5923981, 40.6866523],
                        [-89.5911804, 40.6874068],
                        [-89.5912179, 40.6874475],
                        [-89.5924303, 40.6866848],
                      ],
                    ],
                  },
                },
                {
                  type: "Feature",
                  properties: { Name: "Polygon 2" },
                  geometry: {
                    type: "Polygon",
                    coordinates: [
                      [
                        [-89.5912179, 40.6874475],
                        [-89.5912558, 40.6874303],
                        [-89.5912102, 40.6873958],
                        [-89.5900327, 40.6881117],
                        [-89.5900703, 40.6881503],
                        [-89.5912179, 40.6874475],
                      ],
                    ],
                  },
                },
                {
                  type: "Feature",
                  properties: { Name: "Polygon 3" },
                  geometry: {
                    type: "Polygon",
                    coordinates: [
                      [
                        [-89.5900969, 40.6881302],
                        [-89.5899896, 40.6880204],
                        [-89.5899668, 40.6880072],
                        [-89.589948, 40.6879899],
                        [-89.5896838, 40.6877347],
                        [-89.5896731, 40.6877133],
                        [-89.5896597, 40.6876838],
                        [-89.5896355, 40.6876594],
                        [-89.5896262, 40.6876442],
                        [-89.5895819, 40.6876584],
                        [-89.5896114, 40.6876787],
                        [-89.5896235, 40.687693],
                        [-89.5896195, 40.6877123],
                        [-89.5896141, 40.6877235],
                        [-89.5900703, 40.6881503],
                        [-89.5900969, 40.6881302],
                      ],
                    ],
                  },
                },
                {
                  type: "Feature",
                  properties: { Name: "Polygon 5" },
                  geometry: {
                    type: "Polygon",
                    coordinates: [
                      [
                        [-89.5895815, 40.6874292],
                        [-89.5895567, 40.6874078],
                        [-89.5891593, 40.6876462],
                        [-89.5889125, 40.6877967],
                        [-89.588529, 40.6880347],
                        [-89.5882715, 40.6881831],
                        [-89.5879094, 40.6884211],
                        [-89.5876519, 40.6885757],
                        [-89.5873676, 40.6887506],
                        [-89.5871718, 40.6888604],
                        [-89.5867775, 40.6891106],
                        [-89.5868312, 40.6891553],
                        [-89.5870216, 40.6890394],
                        [-89.5872442, 40.6889051],
                        [-89.5875178, 40.6887445],
                        [-89.5877136, 40.6886082],
                        [-89.5879684, 40.6884597],
                        [-89.588191, 40.6883092],
                        [-89.5884968, 40.6881181],
                        [-89.5885183, 40.6880937],
                        [-89.5885639, 40.6880652],
                        [-89.5886041, 40.6880347],
                        [-89.5895815, 40.6874292],
                      ],
                    ],
                  },
                },
                {
                  type: "Feature",
                  properties: { Name: "Polygon 6" },
                  geometry: {
                    type: "Polygon",
                    coordinates: [
                      [
                        [-89.587067, 40.6893182],
                        [-89.5869892, 40.6892348],
                        [-89.587008, 40.6890538],
                        [-89.586953, 40.6890029],
                        [-89.5867827, 40.6891168],
                        [-89.5870147, 40.6893487],
                        [-89.587067, 40.6893182],
                      ],
                    ],
                  },
                },
                {
                  type: "Feature",
                  properties: { Name: "Polygon 10" },
                  geometry: {
                    type: "Polygon",
                    coordinates: [
                      [
                        [-89.5900618, 40.6880981],
                        [-89.5898727, 40.6882253],
                        [-89.5899009, 40.6882507],
                        [-89.5900969, 40.6881302],
                        [-89.5900618, 40.6880981],
                      ],
                    ],
                  },
                },
                {
                  type: "Feature",
                  properties: { Name: "Polygon 11" },
                  geometry: {
                    type: "Polygon",
                    coordinates: [
                      [
                        [-89.5898969, 40.6882131],
                        [-89.5897628, 40.6882924],
                        [-89.5897721, 40.6883066],
                        [-89.5896233, 40.6883961],
                        [-89.5896125, 40.688388],
                        [-89.5895401, 40.6884368],
                        [-89.5894248, 40.6884978],
                        [-89.5892437, 40.6886117],
                        [-89.5890788, 40.6887134],
                        [-89.5889849, 40.6887673],
                        [-89.5889004, 40.6888212],
                        [-89.5887811, 40.6888883],
                        [-89.5888092, 40.6889127],
                        [-89.5889058, 40.6888598],
                        [-89.5890386, 40.6887774],
                        [-89.5891351, 40.6887144],
                        [-89.5892223, 40.6886625],
                        [-89.5893255, 40.6886076],
                        [-89.5894275, 40.6885375],
                        [-89.5895321, 40.6884724],
                        [-89.5897641, 40.6883442],
                        [-89.5898633, 40.6882731],
                        [-89.589921, 40.6882364],
                        [-89.5898969, 40.6882131],
                      ],
                    ],
                  },
                },
                {
                  type: "Feature",
                  properties: { Name: "Polygon 12" },
                  geometry: {
                    type: "Polygon",
                    coordinates: [
                      [
                        [-89.5888323, 40.6889005],
                        [-89.5888068, 40.6888746],
                        [-89.5886392, 40.6889738],
                        [-89.5886713, 40.6889982],
                        [-89.5887424, 40.688956],
                        [-89.5888323, 40.6889005],
                      ],
                    ],
                  },
                },
                {
                  type: "Feature",
                  properties: { Name: "Polygon 13" },
                  geometry: {
                    type: "Polygon",
                    coordinates: [
                      [
                        [-89.5886498, 40.6889684],
                        [-89.5885157, 40.6890457],
                        [-89.5884245, 40.6891037],
                        [-89.5883199, 40.6891637],
                        [-89.5882206, 40.6892308],
                        [-89.5881066, 40.689304],
                        [-89.587947, 40.6893975],
                        [-89.5877901, 40.6894931],
                        [-89.5877164, 40.6895399],
                        [-89.5875903, 40.6896172],
                        [-89.5874763, 40.6896894],
                        [-89.5875031, 40.6897118],
                        [-89.5876225, 40.6896396],
                        [-89.5876976, 40.6895887],
                        [-89.5878169, 40.6895186],
                        [-89.5879108, 40.6894616],
                        [-89.5880114, 40.6893996],
                        [-89.5880865, 40.6893548],
                        [-89.5882233, 40.6892704],
                        [-89.5883816, 40.6891799],
                        [-89.5885116, 40.6891006],
                        [-89.5886779, 40.6889918],
                        [-89.5886498, 40.6889684],
                      ],
                    ],
                  },
                },
                {
                  type: "Feature",
                  properties: { Name: "Polygon 14" },
                  geometry: {
                    type: "Polygon",
                    coordinates: [
                      [
                        [-89.5875176, 40.6896992],
                        [-89.5874935, 40.6896788],
                        [-89.5872091, 40.6898517],
                        [-89.5872386, 40.6898731],
                        [-89.5875176, 40.6896992],
                      ],
                    ],
                  },
                },
                {
                  type: "Feature",
                  properties: { Name: "Polygon 12" },
                  geometry: {
                    type: "Polygon",
                    coordinates: [
                      [
                        [-89.587092, 40.6893405],
                        [-89.5869096, 40.6891188],
                        [-89.5866173, 40.6892775],
                        [-89.5866012, 40.6893649],
                        [-89.586742, 40.6895347],
                        [-89.5869981, 40.6898052],
                        [-89.5871436, 40.6898149],
                        [-89.5872261, 40.6898835],
                        [-89.5873327, 40.6898205],
                        [-89.5872932, 40.6897818],
                        [-89.5872301, 40.6897727],
                        [-89.587033, 40.6897518],
                        [-89.5868144, 40.6895103],
                        [-89.587092, 40.6893405],
                      ],
                    ],
                  },
                },
                {
                  type: "Feature",
                  properties: { Name: "Polygon 13" },
                  geometry: {
                    type: "Polygon",
                    coordinates: [
                      [
                        [-89.5896355, 40.6876594],
                        [-89.5895685, 40.6875826],
                        [-89.5895564, 40.6875791],
                        [-89.589537, 40.6875618],
                        [-89.5895591, 40.6875323],
                        [-89.5895745, 40.6874962],
                        [-89.5895506, 40.6874337],
                        [-89.5895237, 40.6874398],
                        [-89.5895103, 40.6874632],
                        [-89.5895398, 40.6874805],
                        [-89.5895323, 40.6875099],
                        [-89.5895142, 40.6875308],
                        [-89.5895021, 40.6875694],
                        [-89.5895155, 40.6875882],
                        [-89.5895229, 40.6876086],
                        [-89.589596, 40.6876675],
                        [-89.5896355, 40.6876594],
                      ],
                    ],
                  },
                },
              ],
            },
          });

        !map.getLayer("RiverfrontLineLayer") &&
          map.addLayer({
            id: "RiverfrontLineLayer",
            type: "fill",
            source: "riverFront",
            paint: {
              "fill-color": "#7859bc",
              "fill-opacity": 0.75,
            },
            filter: ["==", "$type", "Polygon"],
          });

        !map.getLayer("Riverfront") &&
          map.addLayer({
            id: "Riverfront",
            type: "symbol",
            source: "riverFront",
            layout: {
              "icon-image": "custom-marker",
              "icon-size": 0.65,
            },
            filter: ["==", "$type", "Point"],
          });
      }

      if (
        robotmsgData &&
        robotmsgData.find((robotData) => robotData.location === "zaragoza")
      ) {
        !map.getLayer("zaragoza-okoutline") &&
          map.addLayer({
            id: "zaragoza-okoutline",
            type: "line",
            source: {
              type: "vector",
              url: "mapbox://mrlakshyagupta.cliockddp17ir2onppxm67ul4-2322c",
            },
            "source-layer": "Zaragoza-OK",
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": "#0000ff",
              "line-width": 2,
            },
          });

        !map.getLayer("zaragoza-goodoutline") &&
          map.addLayer({
            id: "zaragoza-goodoutline",
            type: "line",
            source: {
              type: "vector",
              url: "mapbox://mrlakshyagupta.cliockw3y0x522ophzjv42pw5-05s1c",
            },
            "source-layer": "Zaragoza-GOOD",
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": "#027148",
              "line-width": 2,
            },
          });

        !map.getLayer("zaragoza-difficultoutline") &&
          map.addLayer({
            id: "zaragoza-difficultoutline",
            type: "line",
            source: {
              type: "vector",
              url: "mapbox://mrlakshyagupta.cliocljvn0yvs2cphplde8mwp-65qz7",
            },
            "source-layer": "Zaragoza-DIFFICULT",
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": "#8B0000",
              "line-width": 2,
            },
          });

        !map.getLayer("zaragoza-nooutline") &&
          map.addLayer({
            id: "zaragoza-nooutline",
            type: "line",
            source: {
              type: "vector",
              url: "mapbox://mrlakshyagupta.cliocqzf906lb2bo9xa6o69r5-5ubuf",
            },
            "source-layer": "Zaragoza-NO",
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": "#FF0000",
              "line-width": 2,
            },
          });
      }

      if (
        !map.getSource("robotPath") &&
        ((robotDetails[robotId].hasOwnProperty("path") &&
          robotDetails[robotId].path.length > 0) ||
          (robotDetails[robotId].hasOwnProperty("robotCommandList") &&
            robotDetails[robotId].robotCommandList.length > 0 &&
            robotDetails[robotId].robotCommandList.find(
              (x) => x.actionPriority === 1
            ) &&
            robotDetails[robotId].robotCommandList
              .find((x) => x.actionPriority === 1)
              .actionType.includes("MOVE")))
      ) {
        let newrobotPath = [];
        newrobotPath =
          robotDetails[robotId].path &&
          robotDetails[robotId].path.map((pathDirection) => [
            pathDirection.lng,
            pathDirection.lat,
          ]);

        map.addSource("robotPath", {
          type: "geojson",
          data: {
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: newrobotPath,
            },
          },
        });

        map.addLayer({
          id: "path",
          type: "line",
          source: "robotPath",
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#0f0",
            "line-width": 5,
          },
        });
      }

      if (
        !userControllingMap &&
        robotDetails[robotId].hasOwnProperty("path") &&
        robotDetails[robotId].path.length > 0 &&
        robotDetails[robotId].hasOwnProperty("robotCommandList") &&
        robotDetails[robotId].robotCommandList.length > 0 &&
        robotDetails[robotId].robotCommandList.find(
          (x) => x.actionPriority === 1
        ) &&
        robotDetails[robotId].robotCommandList
          .find((x) => x.actionPriority === 1)
          .actionType.includes("MOVE")
      ) {
        let newrobotPath = [];
        newrobotPath =
          robotDetails[robotId].path &&
          robotDetails[robotId].path.map((pathDirection) => [
            pathDirection.lng,
            pathDirection.lat,
          ]);

        // Create a 'LngLatBounds' with both corners at the first coordinate.
        const bounds = new mapboxgl.LngLatBounds(
          newrobotPath[0],
          newrobotPath[0]
        );

        // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
        for (const coord of newrobotPath) {
          bounds.extend(coord);
        }

        map.fitBounds(bounds, {
          padding: 20,
        });
      } else if (!userControllingMap && nogoZonesCoordinates.length > 0) {
        // Create a 'LngLatBounds' with both corners at the first coordinate.
        const bounds = new mapboxgl.LngLatBounds(
          nogoZonesCoordinates[0],
          nogoZonesCoordinates[0]
        );

        // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
        for (const coord of nogoZonesCoordinates) {
          bounds.extend(coord);
        }

        map.fitBounds(bounds, {
          padding: 20,
        });
      } else if (!userControllingMap && geofenceCoordinates.length > 0) {
        // Create a 'LngLatBounds' with both corners at the first coordinate.
        const bounds = new mapboxgl.LngLatBounds(
          geofenceCoordinates[0],
          geofenceCoordinates[0]
        );

        // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
        for (const coord of geofenceCoordinates) {
          bounds.extend(coord);
        }

        map.fitBounds(bounds, {
          padding: 20,
        });
      }
    }

    // move robot marker
    if (robotMarker) {
      if (
        JSON.parse(process.env.REACT_APP_HEADING) &&
        (robotDetails[robotId].headingSlam || robotDetails[robotId].heading)
      ) {
        robotMarker
          .setLngLat([
            slamPosition
              ? parseFloat(robotDetails[robotId].longitudeSlam)
              : parseFloat(robotDetails[robotId].longitude),
            slamPosition
              ? parseFloat(robotDetails[robotId].latitudeSlam)
              : parseFloat(robotDetails[robotId].latitude),
          ])
          .setRotation(
            parseFloat(
              robotDetails[robotId].headingSlam
                ? robotDetails[robotId].headingSlam
                : robotDetails[robotId].heading
            )
          )
          .setRotationAlignment("map");
      } else {
        robotMarker.setLngLat([
          slamPosition
            ? parseFloat(robotDetails[robotId].longitudeSlam)
            : parseFloat(robotDetails[robotId].longitude),
          slamPosition
            ? parseFloat(robotDetails[robotId].latitudeSlam)
            : parseFloat(robotDetails[robotId].latitude),
        ]);
      }
    }

    // Destination Marker on move
    if (
      destinationMarker &&
      robotDetails[robotId].hasOwnProperty("robotCommandList") &&
      robotDetails[robotId].robotCommandList.length > 0 &&
      robotDetails[robotId].robotCommandList.find((x) => x.actionPriority === 1)
    ) {
      let destinationlng = robotDetails[robotId].robotCommandList.find(
        (x) => x.actionPriority === 1
      ).longitude;
      let destinationlat = robotDetails[robotId].robotCommandList.find(
        (x) => x.actionPriority === 1
      ).latitude;

      if (destinationlng && destinationlat) {
        // destinationMarkerDiv.style.visibility = "visible";
        destinationMarker.setLngLat([destinationlng, destinationlat]);

        if (!userControllingMap) {
          const mySource = map.getSource("robotPath");
          if (map && mySource) {
            // Create a 'LngLatBounds' with both corners at the first coordinate.
            const bounds = new mapboxgl.LngLatBounds(
              mySource._options.data.geometry.coordinates[0],
              mySource._options.data.geometry.coordinates[0]
            );

            // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
            for (const coord of map &&
              mySource &&
              mySource._options.data.geometry.coordinates) {
              bounds.extend(coord);
            }

            bounds.extend([
              slamPosition
                ? parseFloat(robotDetails[robotId].longitudeSlam)
                : parseFloat(robotDetails[robotId].longitude),
              slamPosition
                ? parseFloat(robotDetails[robotId].latitudeSlam)
                : parseFloat(robotDetails[robotId].latitude),
            ]);

            bounds.extend([destinationlng, destinationlat]);

            destinationMarkerForBound = [destinationlng, destinationlat];

            map.fitBounds(bounds, {
              padding: 100,
              bearing: robotDetails[robotId].headingSlam
                ? parseFloat(robotDetails[robotId].headingSlam)
                : 0,
              duration: 200,
            });
          }
        }
      }
    }

    let offset = 0.00003;

    setNogoZonesCoordinates(
      nogoZones.map((data) => [data.lng, data.lat + offset])
    );

    setGeofenceCoordinates(
      geofenceZone.map((data) => [data.lng, data.lat + offset])
    );

    let polygonArray = [];

    fleetZones.map((polygon, index) => {
      polygonArray.push(polygon.map((data) => [data.lng, data.lat]));
      return polygonArray;
    });

    setPolygonArray(polygonArray);
    if (robotDetails[robotId] && robotDetails[robotId].hasOwnProperty("path")) {
      updateRobotPath();
    }
  }, [
    robotDetails[robotId].longitude,
    robotDetails[robotId].latitude,
    robotMarker,
    destinationMarker,
    robotId,
    fleetZones,
    geofenceZone,
    nogoZones,
    robotPathCheck,
    dispatch,
    robotDirections,
    robotDetails,
    robotDetails[robotId],
    robotDetails[robotId].path,
  ]);

  return (
    <>
      <div className="relative h-full" style={{ width: width }}>
        {/* <div className="sidebar bg-[rgba(35,55,75,0.9)] text-white p-[6px_12px] z-[1] absolute top-0 left-0 m-3 rounded-[4px] h-[35px]">
          Ottobot Position Simple [
          {!slamPosition && robotDetails[robotId].latitude},
          {!slamPosition && robotDetails[robotId].longitude}] | Ottobot Position
          Slam [{slamPosition && robotDetails[robotId]?.latitudeSlam},
          {slamPosition && robotDetails[robotId]?.longitudeSlam}] | Zoom: {zoom}
        </div> */}
        {userControllingMap && (
          <div
            className="cursor-pointer flex items-center gap-2 sidebar bg-[rgba(35,55,75,0.9)] text-white p-[6px_12px] z-[1] absolute bottom-0 right-0 m-3 mb-4 rounded-[4px] h-[30px]"
            onClick={() => setUserControllingMap(false)}
          >
            <FiNavigation2 />
            <span className="text-sm">Re-centre</span>
          </div>
        )}
        <div
          id="map-container-id"
          className="map-container"
          style={{ height: "100%", width: "100%" }}
        />
      </div>
    </>
  );
};
export default memo(MapboxMain);
