import { memo, useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrderStatus } from "../../redux/actions";
import RobotActionModal from "../layout/modals/RobotActionModal";

function RobotActions(props) {
  const audioRef = useRef(null);

  let robotId = props.robotId;

  const dispatch = useDispatch();

  const robotDetails = useSelector((state) => state.robotDetailsReducer);
  const { orderStatus } = useSelector((state) => state.orderStatusReducer);

  const getrobotactionStatus = (commandlistdata, orderId, actionId) => {
    switch (commandlistdata?.actionStatus) {
      case "0":
        return (
          <div className="RobotActions-button-wrapper">
            <RobotActionModal
              name="Start"
              actionId={actionId}
              orderId={orderId}
              number="1"
              robotId={props.robotId}
              commandlistdata={commandlistdata}
            />
          </div>
        );
      case "1":
        return (
          <div className="RobotActions-button-wrapper">
            <RobotActionModal
              name="Finish"
              actionId={actionId}
              orderId={orderId}
              number="3"
              robotId={props.robotId}
              commandlistdata={commandlistdata}
            />
          </div>
        );
      case "4":
        return (
          <div className="RobotActions-button-wrapper">
            <RobotActionModal
              name="Finish"
              actionId={actionId}
              orderId={orderId}
              number="3"
              robotId={props.robotId}
              commandlistdata={commandlistdata}
            />
          </div>
        );
      case null:
        return (
          <div className="RobotActions-button-wrapper">
            <audio
              preload="auto"
              ref={audioRef}
              loop
              autoPlay={true}
              id="playaudio"
            >
              <source
                src="/static/media/alert.aa9b8554cbeaf7968e81.wav"
                type="audio/wav"
              ></source>
            </audio>
            <RobotActionModal
              name="Start"
              actionId={actionId}
              orderId={orderId}
              number="1"
              robotId={props.robotId}
              commandlistdata={commandlistdata}
            />
          </div>
        );
      default:
        return null;
    }
  };

  const getouterborder = (commandlistdata) => {
    switch (commandlistdata[0]?.actionStatus) {
      case "4":
        return "RobotActions-content-wrapper-div";
      default:
        return "RobotActions-content-wrapper-div ";
    }
  };

  // const updateState = useCallback(async () => {
  //   if (
  //     robotDetails[robotId]?.robotCommandList &&
  //     robotDetails[robotId]?.robotCommandList.length > 0
  //   )
  //     dispatch(
  //       getOrderStatus(robotDetails[robotId]?.robotCommandList[0].orderId)
  //     );
  // }, [dispatch, robotDetails, robotId]);

  // const useIntervalAsync = (callbackFunction, intervalInMs) => {
  //   const timeout = useRef();

  //   const apiCall = useCallback(async () => {
  //     await callbackFunction();
  //     if (timeout.current) {
  //       clearTimeout(timeout.current);
  //       timeout.current = null;
  //     }
  //     timeout.current = window.setTimeout(apiCall, intervalInMs);
  //   }, [callbackFunction, intervalInMs]);

  //   useEffect(() => {
  //     apiCall();
  //     return () => {
  //       clearTimeout(timeout.current);
  //     };
  //   }, [apiCall]);
  // };

  // useIntervalAsync(updateState, 2000);

  useEffect(() => {
    const audio = audioRef.current;

    const handleAudioLoad = () => {
      if (sessionStorage.getItem("audioPaused") === "false") {
        audio.play();
      }
    };

    if (audio) {
      audio.oncanplay = handleAudioLoad;
      return () => {
        audio.oncanplay = null;
      };
    }
  }, []);

  useEffect(() => {
    if (audioRef.current) {
      if (audioRef.current.paused) {
        sessionStorage.setItem("audioPaused", "true");
      } else {
        sessionStorage.setItem("audioPaused", "false");
      }
    }
  }, []);

  return (
    <div
      className={`${
        robotDetails[robotId]?.robotCommandList &&
        robotDetails[robotId]?.robotCommandList.length > 0
          ? getouterborder(robotDetails[robotId]?.robotCommandList)
          : "no__actions"
      }`}
    >
      {robotDetails[robotId]?.robotCommandList &&
      robotDetails[robotId]?.robotCommandList.length > 0 ? (
        robotDetails[robotId]?.robotCommandList.map((item, index) => (
          <div key={"robot__actions_" + index}>
            {item.actionPriority === 1 && (
              <div>
                <h3>
                  {" "}
                  Ottobot{" "}
                  {item.message === "MOVEHOME" ? (
                    <> Enroute to Home Location</>
                  ) : (
                    <>
                      <>{orderStatus}</>
                    </>
                  )}
                </h3>
                {item.actionStatus === null ||
                item.actionStatus === undefined ? null : (
                  <span className="robot__teleop__location">
                    {item.actionType}
                  </span>
                )}
                <p>
                  {" "}
                  Order Id : <span>{item.orderId}</span>
                </p>
                <p>
                  {" "}
                  Action Status :{" "}
                  <span>
                    {item.actionStatus ? item.actionStatus : "No status"}
                  </span>
                </p>
                {item.actionType.includes("MOVE") ? (
                  ""
                ) : (
                  <p>
                    {" "}
                    Compartement:<span className="">{item.boxId}</span>
                  </p>
                )}
                {getrobotactionStatus(item, item.orderId, item.actionId)}
              </div>
            )}
          </div>
        ))
      ) : (
        <span className="robot__no__action"> No actions to show</span>
      )}
    </div>
  );
}

export default memo(RobotActions);
